<template>
  <div
    :class="{ active: isActive }"
    class="product-list-wrapper listing tab-pane fade in"
    role="tabpanel"
  >
    <!-- 放置內容區塊 -->
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    name: { required: true },
    label: { required: true },
    role: { required: true }
  },
  computed: {
    isActive() {
      // this.$parent.value取得tabs裡面value的值
      return this.name === this.$parent.value;
    }
  }
};
</script>
