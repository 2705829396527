<template>
  <div>
    <div class="list-console">
      <ul role="tablist" class="category-tab">
        <li
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{ active: tab.name === value }"
          role="presentation"
        >
          <!-- 產出頁籤，而tab.name是從調用端傳給tab，再取this.$children來取得裡面的props -->
          <a @click="selectTab(tab.name)">
            {{ tab.label }}
          </a>
        </li>
      </ul>
      <slot name="toolBar"></slot>
    </div>
    <div class="list-wrap double-top">
      <div class="container">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['value'],
  data() {
    return {
      tabs: []
    };
  },
  methods: {
    selectTab(tabName) {
      this.$emit('input', tabName);
    },
    updateSearch(search) {
      this.$emit('on-search', search);
    }
  },
  mounted() {
    let vm = this;
    // tabs是從子元件取得的，也就是tab的部份，可以取得子元件的vue內容
    // this.tabs = this.$children;
    vm.$children.forEach(ele => {
      if (ele.role === 'tab') {
        vm.tabs.push({
          label: ele.label,
          name: ele.name
        });
      }
    });
  }
};
</script>
