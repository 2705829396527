<template>
  <div class="content-block">
    <div
      v-show="!hideTab"
      class="col-title"
      data-toggle="collapse"
      data-parent="#accordion"
      :href="`#${groupTypeId}`"
      aria-expanded="true"
      :aria-controls="groupTypeId"
    >
      <div class="sub-title">
        <slot name="group-title"></slot>
        <!-- 09:00-12:00三民高中一號出口
        <span>可口可樂:70瓶 雪碧:70瓶</span> -->
      </div>
    </div>
    <div class="col-content collapse in" :id="groupTypeId">
      <div
        class="product-item-block"
        v-for="(order, index) in orders"
        :key="`${groupTypeId}-orders-${index}`"
      >
        <div class="container">
          <div class="product-item">
            <div class="productimg">
              <img :src="groupCover" alt="封面圖" />
            </div>
            <div class="product-info-wrap">
              <div class="product-info">
                <div class="user-name">
                  <span>{{ order.member.name }}</span>
                  <span>
                    -{{
                      groupBuyingOrderItemsCount(order.groupBuyingOrderDetails)
                    }}份/{{
                      groupBuyingOrderDetailsCount(
                        order.groupBuyingOrderDetails
                      )
                    }}元
                  </span>
                  <a
                    v-if="order.platform == 'telegram'"
                    class="s-msg tele"
                    href="#notice-msg"
                  ></a>
                  <a
                    v-else-if="order.platform == 'facebook'"
                    class="s-msg fb"
                    href="#notice-msg"
                  ></a>
                  <a
                    v-else-if="order.platform == 'line'"
                    class="s-msg line"
                    href="#notice-msg"
                  ></a>
                </div>
              </div>
              <div class="btn-item-wrap">
                <div class="btn-item">
                  <div
                    class="btn-cta"
                    :class="{ undo: order.payment_status === 1 }"
                    :data-order-index="index"
                    :data-order-id="order.id"
                    :data-order-payment-status="order.payment_status"
                    @click="changePaymentStatus"
                  >
                    {{ order.payment_status_display }}
                  </div>
                  <div class="helper" @click="showHelperPaymentMsg"></div>
                </div>
                <div class="btn-item">
                  <div
                    class="btn-cta"
                    :class="{ undo: order.shipping_status === 1 }"
                    :data-order-index="index"
                    :data-order-id="order.id"
                    :data-order-shipping-status="order.shipping_status"
                    @click="changeShippingStatus"
                  >
                    {{ order.shipping_status_display }}
                  </div>
                  <div class="helper" @click="showHelperDeliveryMsg"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <router-link
          class="more-details"
          :to="{
            name: 'CreatedGroupFollowedDetail',
            params: {
              group_id: groupId,
              order_id: order.id
            }
          }"
        ></router-link>
      </div>
    </div>
    <MessageAlert
      :show="showMessageModal"
      @close-modal="confirmMsg"
      :msgTitle="msgTitle"
      :msgContent="msgContent"
    ></MessageAlert>
  </div>
</template>

<script>
import MessageAlert from '@/components/Modals/ModalMessageAlert';
import { updateMemberGroupBuyingOrderStatus } from '@/api/group-buying';

export default {
  components: {
    MessageAlert
  },
  props: {
    groupTypeId: {
      required: true,
      type: String
    },
    orders: {
      required: true,
      type: Array
    },
    groupCover: {
      required: false,
      type: String
    },
    hideTab: {
      required: false,
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      //for message
      showMessageModal: false,
      msgTitle: null,
      msgContent: null
    };
  },
  computed: {
    groupId() {
      return this.$route.params.group_id;
    }
  },
  methods: {
    async changePaymentStatus(e) {
      let orderIndex = e.target.dataset.orderIndex;
      let orderId = e.target.dataset.orderId;
      let paymentStatus = e.target.dataset.orderPaymentStatus;

      //跟團人尚未更新付款狀態
      // if (paymentStatus == 1) {
      //   this.showMemberUnpaidMsg();
      //   return;
      // }

      // if (paymentStatus == 1 || paymentStatus == 2) {
      //   //變更狀態為確認已付款
      //   updateMemberGroupBuyingOrderStatus({
      //     member_group_buying_order_id: orderId,
      //     payment_status: 3
      //   }).then(data => {
      //     this.$emit('on-status-change');
      //   });
      // }

      if (paymentStatus == 1 || paymentStatus == 2) {
        //變更狀態為確認已付款
        let data = await updateMemberGroupBuyingOrderStatus({
          member_group_buying_order_id: orderId,
          payment_status: 3
        });

        await updateMemberGroupBuyingOrderStatus({
          member_group_buying_order_id: orderId,
          shipping_status: 2
        });

        this.$emit('on-status-change');
      }
    },
    changeShippingStatus(e) {
      let orderIndex = e.target.dataset.orderIndex;
      let orderId = e.target.dataset.orderId;
      let shippingStatus = e.target.dataset.orderShippingStatus;

      if (shippingStatus != 1) {
        return;
      }

      updateMemberGroupBuyingOrderStatus({
        member_group_buying_order_id: orderId,
        shipping_status: 2
      }).then(() => {
        this.$emit('on-status-change');
      });
    },
    confirmMsg() {
      this.reset();
    },
    reset() {
      this.showMessageModal = false;
      this.msgTitle = null;
      this.msgContent = null;
    },
    showMemberUnpaidMsg() {
      this.showMessageModal = true;
      this.msgTitle = '無法確認付款狀態';
      this.msgContent = '跟團人尚未更新付款狀態';
    },
    showHelperPaymentMsg() {
      this.showMessageModal = true;
      this.msgTitle = '支付說明';
      this.msgContent =
        '如團友已付款，點選此按鈕會顯示【確認已付款】，團友的頁面也會顯示【確認已付款】';
    },
    showHelperDeliveryMsg() {
      this.showMessageModal = true;
      this.msgTitle = '出貨說明';
      this.msgContent =
        '如已出貨，點選此按鈕會顯示【已出貨】，團友的頁面也會顯示【已出貨】';
    },
    groupBuyingOrderItemsCount(orders) {
      var count = 0;

      orders.forEach(detail => {
        count += detail.quantity;
      });

      return count;
    },
    groupBuyingOrderDetailsCount(orders) {
      var subtotal = 0;

      orders.forEach(detail => {
        subtotal += detail.subtotal;
      });

      return subtotal;
    }
  }
};
</script>
