<template>
  <div class="list-button-block">
    <router-link :to="{ name: 'FollowedList', params: { group_id: groupId } }">
      團友跟團
    </router-link>
    <router-link :to="{ name: 'CreateGroup', query: { group_id: groupId } }">
      揪團開團
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      required: true,
      type: [String, Number]
    },
    groupId: {
      required: true,
      type: [String, Number]
    }
  }
};
</script>
