<template>
  <div class="list-button-block">
    <router-link
      v-if="notifyType != `valid-group-notification`"
      :to="{ name: 'CreatedGroupFollowList', params: { group_id: groupId } }"
    >
      團友跟團
    </router-link>
    <router-link
      :to="{ name: 'CreateGroup', query: { group_id: groupId } }"
      v-if="showCreateGroupBtn"
    >
      再開團
    </router-link>
    <a
      href="#"
      v-if="showForceValidBtn"
      data-toggle="modal"
      :data-target="`#modal-helper-forceend-${groupId}`"
    >
      強制結團
    </a>
    <router-link
      v-if="showManageBtn"
      :to="{ name: 'CreatedGroupManageMenu', params: { group_id: groupId } }"
    >
      結團管理
    </router-link>
    <a href="#" v-if="showManageBtn" @click.prevent="setValidGroup">
      領貨通知
    </a>
    <router-link
      v-if="showNotifyBtn"
      :to="{
        name: 'CreatedGroupNotification',
        params: { group_id: groupId, type: notifyType }
      }"
    >
      通知訊息
    </router-link>
    <!-- //TODO: 優化不重複render modal -->
    <div
      class="modal fade"
      :id="`modal-helper-forceend-${groupId}`"
      tabindex="-1"
      role="dialog"
      aria-labelledby="delalert"
    >
      <div class="modal-dialog modal-sm modal-helper">
        <div class="modal-content">
          <div class="modal-title">強制結團</div>
          <div class="helper-content">
            <!-- 點擊確定後活動將移動至結團通知，請再次確認是否要提前結團 -->
            請再次確認是否要提前結團
          </div>
          <div class="modal-btn-block">
            <div class="modal-btn cancel" data-dismiss="modal">取消</div>
            <div class="modal-btn" data-dismiss="modal" @click="forceValid">
              確定
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGroupBuyingManageInfo, forceValid } from '@/api/group-buying';
import { setValidGroup } from '@/api/group-buying';
import { get } from 'lodash';

export default {
  data() {
    return {
      haveToFinishedStep2: process.env.VUE_APP_HAVE_TO_FINISHED_STEP2 === 'true'
    };
  },
  props: {
    status: {
      required: true,
      type: [String, Number]
    },
    groupId: {
      required: true,
      type: [String, Number]
    }
  },
  computed: {
    notificationRoute() {
      return 0;
    },
    showNotifyBtn() {
      return this.status == 1 || this.status == 3;
    },
    showCreateGroupBtn() {
      return this.status !== 1;
    },
    showForceValidBtn() {
      return this.status == 1;
    },
    showManageBtn() {
      return this.status == 2;
    },
    notifyType() {
      let type = 'default';

      if (this.status == 3) {
        type = 'valid-group-notification';
      }

      return type;
    },
    finishedStep2() {
      return get(this.group, 'finished_step2') === 1 ? true : false;
    },
    canSetValidGroup() {
      return this.finishedStep2 || this.haveToFinishedStep2 === false;
    }
  },
  watch: {
    groupId: {
      immediate: true,
      handler() {
        if (this.showManageBtn) this.setData();
      }
    }
  },
  methods: {
    setData() {
      getGroupBuyingManageInfo(this.groupId)
        .then(data => {
          this.group = data;
        })
        .catch(() => {
          // this.$router.push({
          //   name: 'CreatedGroupList'
          // });
        });
    },
    forceValid() {
      forceValid(this.groupId).then(() => {
        this.$emit('on-group-valid', this.groupId);
      });
    },
    setValidGroup() {
      if (this.canSetValidGroup === false) {
        return;
      }

      setValidGroup({
        group_buying_order_id: this.groupId
      }).then(data => {
        this.toCreatedGroupNotification();
      });
    },
    toCreatedGroupNotification() {
      this.$router.push({
        name: 'CreatedGroupNotification',
        params: {
          group_id: this.groupId,
          type: 'valid-group-notification'
        }
      });
    }
  }
};
</script>
