<template>
  <div class="product-block">
    <div class="productwrap">
      <!-- 轉歷史訂單Checkbox -->
      <slot name="history-checkbox-block" :group="group"></slot>

      <div class="productimg">
        <img :src="group.cover" alt="封面圖" />
      </div>
      <div class="product-info-wrap">
        <a
          v-if="`valid-group` == tabName"
          v-on:click="collapsed = !collapsed"
          :class="[collapsed ? 'fa-chevron-up' : 'fa-chevron-down']"
        ></a>
        <router-link class="group-link" :to="linkObject">
          <ul class="product-info">
            <li class="status">
              {{ group.status_display }}
              <template v-if="group.is_arrival == 1 && this.type == 2">
                - 已到貨 {{ unpaidInfo }}
              </template>
              <template v-else-if="group.is_arrival == 1 && this.type == 1">
                - 已到貨 {{ unpaidMemberCount }}
              </template>
            </li>
            <li class="product-name">
              {{ group.name }}
            </li>
            <li class="time">{{ group.time }}</li>
          </ul>
        </router-link>
        <slot name="list-button-block" :group="group"></slot>
      </div>
    </div>

    <!-- 團友跟團列表 -->
    <slot name="followed-list-block" :group="group" :show="collapsed"></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      collapsed: false
    };
  },
  props: {
    type: {
      required: false,
      type: [Number, String],
      default() {
        return 1; // 1: 開團者用 2: 跟團者用
      }
    },
    group: {
      required: true,
      type: Object
    },
    tabName: {
      required: false,
      type: String,
      default() {
        return '';
      }
    }
  },
  computed: {
    linkObject() {
      if (this.type == 1) {
        //分享頁面
        return {
          name: 'CreatedGroupInfo',
          params: { group_id: this.group.id }
        };
      }
      //跟團頁面
      return { name: 'GroupInfo', params: { group_id: this.group.id } };
    },
    unpaidInfo() {
      if (this.group.member_total_price_sum) {
        return `:${this.group.member_total_items_count_sum}${this.group.item_unit}/${this.group.member_total_price_sum}元`;
      }

      return '';
    },
    unpaidMemberCount() {
      return `- 未付${this.group.member_total}人`;
    }
  }
};
</script>

<style>
.is-collapsed {
  display: none;
}

.fa-chevron-up:before {
  font-family: easyicon;
  position: absolute;
  content: '';
  transform: rotate(90deg);
  font-size: 2rem;
  color: #50af5e;
  right: 2rem;
}

.fa-chevron-down:before {
  font-family: easyicon;
  position: absolute;
  content: '';
  font-size: 2rem;
  transform: rotate(-90deg);
  color: #50af5e;
  right: 2rem;
}

@media screen and (min-width: 415px) {
  .fa-chevron-up {
    position: relative;
  }
  .fa-chevron-up:before {
    right: -12em;
  }
  .fa-chevron-down {
    position: relative;
  }
  .fa-chevron-down:before {
    right: -12em;
  }
}

.check-div {
  width: 7%;
}

.check-div .check-wrap {
  position: relative;
}

.check-div .check-wrap label {
  color: #979797;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: normal;
  margin: 0;
}

.check-div .check-wrap label:before {
  content: '';
  width: 20px;
  height: 20px;
  border: 1px solid #979797;
  border-radius: 5px;
  display: block;
  margin-right: 5px;
  font-family: easyicon;
}

.check-div .check-wrap input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.check-div .check-wrap input:checked + label:before {
  background: #50af5e;
  border-color: #50af5e;
  content: '';
  color: #fff;
}
</style>
