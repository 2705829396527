var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[("valid-group" == _vm.tabName)?_c('div',{staticClass:"history-button-block"},[_c('a',{staticClass:"btn btn-default",attrs:{"data-toggle":"modal","data-target":"#modal-helper-approve"}},[_vm._v(" 批次轉歷史 ")])]):_vm._e(),_c('span',{staticStyle:{"color":"#E30000","font-size":"16px","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.totalAmountString)+" ")]),_vm._l((_vm.dataListRendering),function(group,index){return _c('GroupItem',{key:("group" + index),attrs:{"group":group,"type":_vm.type,"tab-name":_vm.tabName},scopedSlots:_vm._u([{key:"history-checkbox-block",fn:function(ref){
var group = ref.group;
return [("valid-group" == _vm.tabName)?_c('div',{staticClass:"check-div"},[_c('div',{staticClass:"check-wrap"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.historyCheck),expression:"historyCheck"}],attrs:{"type":"checkbox","name":"groupIds[]","id":("group_" + (group.id))},domProps:{"value":group.id,"checked":Array.isArray(_vm.historyCheck)?_vm._i(_vm.historyCheck,group.id)>-1:(_vm.historyCheck)},on:{"change":function($event){var $$a=_vm.historyCheck,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=group.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.historyCheck=$$a.concat([$$v]))}else{$$i>-1&&(_vm.historyCheck=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.historyCheck=$$c}}}}),_c('label',{attrs:{"for":("group_" + (group.id))}})])]):_vm._e()]}},{key:"list-button-block",fn:function(ref){
var group = ref.group;
return [(_vm.type == 1)?_c('GroupManageBtnList',{attrs:{"status":group.status,"group-id":group.id},on:{"on-group-valid":_vm.afterGroupValid}}):_vm._e(),(_vm.type == 2)?_c('GroupBtnList',{attrs:{"status":group.status,"group-id":group.id}}):_vm._e()]}},{key:"followed-list-block",fn:function(ref){
var group = ref.group;
var show = ref.show;
return [("valid-group" == _vm.tabName)?_c('GroupFollowList',{directives:[{name:"show",rawName:"v-show",value:(show),expression:"show"}],attrs:{"status":group.status,"group-id":group.id}}):_vm._e()]}}],null,true)})}),("valid-group" == _vm.tabName)?_c('div',{staticClass:"modal fade",attrs:{"id":"modal-helper-approve","tabindex":"-1","role":"dialog","aria-labelledby":"delalert"}},[_c('div',{staticClass:"modal-dialog modal-sm modal-helper"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-title"},[_vm._v("銀貨兩訖")]),_c('div',{staticClass:"helper-content"},[_vm._v(" 點擊確認後成員列表狀態都將更改成【確認已付款】、【確認已收貨】，活動也會移至歷史資料，請再次確認是否全部交易完成 ")]),_c('div',{staticClass:"modal-btn-block"},[_c('div',{staticClass:"modal-btn cancel",attrs:{"data-dismiss":"modal"}},[_vm._v("取消")]),_c('div',{staticClass:"modal-btn",attrs:{"id":"approve","data-dismiss":"modal"},on:{"click":function($event){$event.preventDefault();return _vm.setCompleted($event)}}},[_vm._v(" 確定 ")])])])])]):_vm._e(),(!_vm.loadAll)?_c('scroll-loader',{attrs:{"loader-method":_vm.getData,"loader-enable":!_vm.loadAll}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }