<template>
  <div class="product-block">
    <div class="product-info-wrapper">
      <div class="content-block">
        <div class="container">
          <!-- 團購資訊 -->
          <GroupBoard :group="followedList">
            <a class="detail" @click="exportGroupDetails"></a>
          </GroupBoard>
        </div>
      </div>
      <div class="content-block">
        <div>
          <table class="table table-striped table-success">
            <thead>
              <tr>
                <th scope="col">商品名稱</th>
                <th scope="col">人數</th>
                <th scope="col">數量</th>
                <th scope="col">小計</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="product in groupBuyingProductDetails"
                :key="product.id"
              >
                <th scope="row">{{ product.fullname }}</th>
                <td>{{ product.buyers_count }}</td>
                <td>
                  {{ product.qty }}
                </td>
                <td>
                  {{ product.subtotal }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- 搜尋框 -->
      <ListSearchBlock
        class="full"
        placeholder="請輸入團友名稱"
        @on-search="updateSearch"
      ></ListSearchBlock>
      <!-- 面交 -->
      <template v-if="followedList.delivery_type == 1">
        <OrderGroup
          v-for="(pickupLocation, index) in pickupLocations"
          @on-status-change="fetchFollowedList"
          :groupTypeId="`pickupLocation-${pickupLocation.id}-${index}`"
          :orders="
            pickLocationOrders(pickupLocation.member_group_buying_orders)
          "
          :group-cover="followedList.cover"
          :key="index"
        >
          <template v-slot:group-title>
            {{ pickupLocation.time_from }}-{{ pickupLocation.time_to }} &nbsp;
            {{ pickupLocation.location }}
            <span>
              <template v-for="product in pickupLocation.products">
                {{ product.fullname }}: {{ product.quantity }}{{ product.unit }}
              </template>
            </span>
          </template>
        </OrderGroup>
      </template>
      <!-- 寄送 -->
      <template v-else>
        <OrderGroup
          @on-status-change="fetchFollowedList"
          groupTypeId="unpaid-group-orders"
          :orders="allOrders"
          :group-cover="followedList.cover"
          :hide-tab="true"
        >
          <!-- <template v-slot:group-title>未付款</template> -->
        </OrderGroup>
      </template>
      <MessageAlert
        :show="showMessageModal"
        @close-modal="confirmMsg"
        :msgTitle="msgTitle"
        :msgContent="msgContent"
      ></MessageAlert>
    </div>
  </div>
</template>

<script>
import ListSearchBlock from '@/components/ListSearchBlock';
import GroupBoard from '@/components/GroupList/GroupBoard';
import OrderGroup from '@/components/CreatedGroup/OrderGroup';
import MessageAlert from '@/components/Modals/ModalMessageAlert';

import {
  getGroupFollowList,
  getExportGroupListSignedUrl
} from '@/api/group-buying';
import { get } from 'lodash';

export default {
  components: {
    GroupBoard,
    OrderGroup,
    ListSearchBlock,
    MessageAlert
  },
  props: {
    status: {
      required: true,
      type: [String, Number]
    },
    groupId: {
      required: true,
      type: [String, Number]
    }
  },
  data() {
    return {
      followedList: {},
      search: null,
      showMessageModal: false,
      msgTitle: null,
      msgContent: null,
      exportUrl: null,
      collapsed: false
    };
  },
  watch: {
    // groupId: {
    //   immediate: true,
    //   handler() {
    //     this.fetchFollowedList();
    //   }
    // }
  },
  computed: {
    groupBuyingProductDetails() {
      return get(this.followedList, 'group_buying_product_details', []);
    },
    pickupLocations() {
      return get(this.followedList, 'pickup_locations', []);
    },
    allOrders() {
      let orders = get(this.followedList, 'member_group_buying_orders', []);

      return this.memberNameFilter(orders);
    },
    paidOrders() {
      let orders = get(
        this.followedList,
        'member_group_buying_orders_paid',
        []
      );

      return this.memberNameFilter(orders);
    },
    unpaidOrders() {
      let orders = get(
        this.followedList,
        'member_group_buying_orders_unpaid',
        []
      );
      return this.memberNameFilter(orders);
    },
    deliveredOrders() {
      let orders = get(
        this.followedList,
        'member_group_buying_orders_delivered',
        []
      );
      return this.memberNameFilter(orders);
    },
    notDeliveredOrders() {
      let orders = get(
        this.followedList,
        'member_group_buying_orders_notDelivered',
        []
      );
      return this.memberNameFilter(orders);
    }
  },
  created() {
    this.fetchFollowedList();

    // $('.col-content').collapse('show');
  },
  methods: {
    pickLocationOrders(orders) {
      return this.memberNameFilter(orders);
    },
    fetchFollowedList() {
      getGroupFollowList(this.groupId)
        .then(data => {
          this.followedList = data;
        })
        .catch(() => {
          // this.$router.push({
          //   name: 'CreatedGroupList'
          // });
        });
    },
    updateSearch(search) {
      this.search = search;
    },
    memberNameFilter(orders) {
      if (!orders) {
        orders = [];
      }

      const searchName = this.search;

      if (!searchName) {
        return orders;
      }

      return orders.filter(order => {
        const name = get(order, 'member.name', '');

        return name.includes(searchName);
      });
    },
    exportGroupDetails() {
      getExportGroupListSignedUrl({
        group_buying_order_id: this.groupId
      }).then(data => {
        this.exportUrl = data;
        this.$copyText(data).then(
          e => {
            this.showExportMsg();
          },
          e => {
            this.confirmMsg();
          }
        );
      });
    },
    reset() {
      this.showMessageModal = false;
      this.msgTitle = null;
      this.msgContent = null;
    },
    confirmMsg() {
      if (this.exportUrl) {
        // Liff另開視窗
        if (this.$liff.isApiAvailable('multipleLiffTransition')) {
          this.$liff.openWindow({
            url: this.exportUrl,
            external: true
          });
        } else {
          window.location = this.exportUrl;
        }
        this.exportUrl = null;
      }
      this.reset();
    },
    showExportMsg() {
      this.showMessageModal = true;
      this.msgTitle = '下載檔案連結已複製';
      this.msgContent =
        '檔案會自動下載，如無自動下載，請使用已複製的連結下載檔案';
    }
  }
};
</script>
