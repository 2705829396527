<template>
  <div>
    <!-- 轉歷史訂單按鈕 -->
    <div class="history-button-block" v-if="`valid-group` == tabName">
      <a
        class="btn btn-default"
        data-toggle="modal"
        data-target="#modal-helper-approve"
      >
        批次轉歷史
      </a>
    </div>

    <span style="color:#E30000;font-size:16px;font-weight:bold">
      {{ totalAmountString }}
    </span>

    <GroupItem
      v-for="(group, index) in dataListRendering"
      :key="`group${index}`"
      :group="group"
      :type="type"
      :tab-name="tabName"
    >
      <template v-slot:history-checkbox-block="{ group }">
        <div class="check-div" v-if="`valid-group` == tabName">
          <div class="check-wrap">
            <input
              type="checkbox"
              name="groupIds[]"
              :id="`group_${group.id}`"
              :value="group.id"
              v-model="historyCheck"
            />
            <label :for="`group_${group.id}`"></label>
          </div>
        </div>
      </template>

      <template v-slot:list-button-block="{ group }">
        <GroupManageBtnList
          v-if="type == 1"
          :status="group.status"
          :group-id="group.id"
          @on-group-valid="afterGroupValid"
        ></GroupManageBtnList>

        <GroupBtnList
          v-if="type == 2"
          :status="group.status"
          :group-id="group.id"
        ></GroupBtnList>
      </template>

      <template v-slot:followed-list-block="{ group, show }">
        <GroupFollowList
          v-if="`valid-group` == tabName"
          v-show="show"
          :status="group.status"
          :group-id="group.id"
        ></GroupFollowList>
      </template>
    </GroupItem>
    <div
      v-if="`valid-group` == tabName"
      class="modal fade"
      id="modal-helper-approve"
      tabindex="-1"
      role="dialog"
      aria-labelledby="delalert"
    >
      <div class="modal-dialog modal-sm modal-helper">
        <div class="modal-content">
          <div class="modal-title">銀貨兩訖</div>
          <div class="helper-content">
            點擊確認後成員列表狀態都將更改成【確認已付款】、【確認已收貨】，活動也會移至歷史資料，請再次確認是否全部交易完成
          </div>
          <div class="modal-btn-block">
            <div class="modal-btn cancel" data-dismiss="modal">取消</div>
            <div
              class="modal-btn"
              id="approve"
              data-dismiss="modal"
              @click.prevent="setCompleted"
            >
              確定
            </div>
          </div>
        </div>
      </div>
    </div>
    <scroll-loader
      v-if="!loadAll"
      :loader-method="getData"
      :loader-enable="!loadAll"
    ></scroll-loader>
  </div>
</template>

<script>
import GroupItem from '@/components/GroupList/Group';
import GroupManageBtnList from '@/components/GroupList/Group/GroupManageBtnList';
import GroupBtnList from '@/components/GroupList/Group/GroupBtnList';
import GroupFollowList from '@/components/GroupList/GroupFollowList';
import PaginateMixin from '@/mixins/paginate';

import { forceComplete } from '@/api/group-buying';

export default {
  mixins: [PaginateMixin],
  components: {
    GroupItem,
    GroupManageBtnList,
    GroupBtnList,
    GroupFollowList
  },
  data() {
    return {
      dataListRendering: [],
      historyCheck: []
    };
  },
  props: {
    type: {
      required: false,
      type: [Number, String],
      default() {
        return 1; // 1: 開團者管理按鈕 2: 跟團者功能按鈕
      }
    },
    isActive: {
      required: false,
      type: Boolean,
      default() {
        return false;
      }
    },
    apiFunc: {
      required: true,
      type: Function
    },
    statusFilter: {
      required: true,
      type: [Array, Number, String]
    },
    searchString: {
      required: false,
      type: String,
      default() {
        return null;
      }
    },
    flowNextTab: {
      type: [String]
    },
    tabName: {
      required: false,
      type: String,
      default() {
        return '';
      }
    },
    totalUnpaidAmount: {
      required: false,
      type: Number,
      default() {
        return 0;
      }
    },
    isLoadAll: {
      required: false,
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  computed: {
    query() {
      return {
        status: this.status,
        page: this.page,
        per_page: this.per_page,
        search: this.search,
        ...this.sort
      };
    },
    status() {
      if (Array.isArray(this.statusFilter)) {
        return this.statusFilter.join();
      }

      return this.statusFilter;
    },
    totalAmountString() {
      if ('valid' == this.tabName && this.type == 2) {
        return '已到貨 總額' + this.totalUnpaidAmount;
      }

      return '';
    }
  },
  watch: {
    api: {
      immediate: true,
      handler() {
        this.api = this.apiFunc;
      }
    },
    searchString: {
      immediate: true,
      handler(search) {
        this.search = search;
      }
    },
    dataList: {
      deep: true,
      handler() {
        this.toppinghasArrived();
      }
    }
  },
  methods: {
    afterGroupValid() {
      this.$emit('setTab', this.flowNextTab);
      this.$emit('upDateAllGroupList');
    },
    toppinghasArrived() {
      if (2 == this.type && 'valid' == this.tabName) {
        let toppings = this.dataList.filter(group => group.is_arrival == 1);

        let others = this.dataList.filter(group => group.is_arrival != 1);

        this.dataListRendering = [...toppings, ...others];
      } else if (1 == this.type && 'valid-group' == this.tabName) {
        let toppings = this.dataList.filter(group => group.is_arrival == 1);

        let others = this.dataList.filter(group => group.is_arrival != 1);

        this.dataListRendering = [...toppings, ...others];
      } else {
        this.dataListRendering = this.dataList;
      }
    },
    async setCompleted() {
      console.log(this.historyCheck);

      for (let index = 0; index < this.historyCheck.length; index++) {
        await forceComplete(this.historyCheck[index]);
      }

      this.historyCheck = [];

      this.$emit('upDateAllGroupList');
    }
  },
  created() {
    this.loadAll = this.isLoadAll;

    this.toppinghasArrived();
  }
};
</script>
